:root {
  --color-primary: #131125;
  --color-black: #000000;
  --color-gray-1: #666666;
  --color-white: #ffffff;
  --color-secondary: #f4f4f4;
  --color-header: #222034;
  --color-first: #d7d7d7;
  --color-second: #999999;
  --color-third: #b3b4bc;
  --color-fourth: #dfe0e2;
  --color-danger: #eb444e;
  --color-neon: #6abe30;
  --color-twitter: #3edbff;
  --color-warning: #f6da4e;
  --color-warning2: #fff84e;
  --color-menu: #1d2125;
  --dropdown-bg: #222033;
  --transition: all 0.3s ease-in-out;
  --font-primary: "VCR_OSD_MONO_1_001", sans-serif !important;
  --h1: 56px;
  --h2: 46px;
  --h3: 40px;
  --h4: 36px;
  --h5: 30px;
  --h6: 24px;
  --font-body-1: 1.5rem; /* 24px */
  --font-body-2: 1.125rem; /* 18px */
  --font-body-3: 1rem; /* 15px */
  --font-body-4: 0.75rem; /* 12px */
  --font-body-5: 1.15rem; /* 20px */
  --line-height-b1: 1.5;
  --line-height-b3: 1.1;
  --wght400: 400;
  --wght500: 500;
  --wght600: 600;
  --wght700: 700;
  --wght800: 800;
  --alpha: 0.5;
}

@font-face {
  font-family: "AvenixelRegular";
  src: url("../fonts/AvenixelRegular.eot");
  src: url("../fonts/AvenixelRegular.eot") format("embedded-opentype"),
    url("../fonts/AvenixelRegular.woff2") format("woff2"),
    url("../fonts/AvenixelRegular.woff") format("woff"),
    url("../fonts/AvenixelRegular.ttf") format("truetype"),
    url("../fonts/AvenixelRegular.svg#AvenixelRegular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "VCR_OSD_MONO_1_001";
  src: url("../fonts/VCR_OSD_MONO_1_001.eot");
  src: url("../fonts/VCR_OSD_MONO_1_001.eot") format("embedded-opentype"),
    url("../fonts/VCR_OSD_MONO_1_001.woff2") format("woff2"),
    url("../fonts/VCR_OSD_MONO_1_001.woff") format("woff"),
    url("../fonts/VCR_OSD_MONO_1_001.ttf") format("truetype"),
    url("../fonts/VCR_OSD_MONO_1_001.svg#VCR_OSD_MONO_1_001") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}
body {
  line-height: 1;
}
*,
*:before,
*:after {
  position: relative;
  box-sizing: inherit;
}
img,
object,
embed {
  max-width: 100%;
  height: auto;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
i,
em {
  font-style: italic;
}
b,
strong {
  font-weight: bold;
}
q {
  quotes: "\201C""\201D""\2018""\2019";
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: inherit;
  font: 100%;
}
th {
  font-weight: bold;
  vertical-align: bottom;
}
td {
  font-weight: normal;
  vertical-align: top;
}
input,
select {
  vertical-align: middle;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
button {
  height: auto;
  padding: 0;
  background: transparent;
  outline: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
mark {
  background: #ff0;
  color: #000;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
  word-wrap: break-word;
}
pre,
code,
kbd,
samp {
  font-family: monospace, sans-serif;
}

b,
.bold,
strong,
.strong {
  font-weight: 800;
}

em,
.em {
  font-style: italic;
}

.txt-dec-none {
  text-decoration: none;
}

html,
body {
  font-size: 1rem;
  color: #4d4e4a;
  font-family: "Inter", sans-serif;
}
.fw-regular {
  font-weight: 400;
}
.fw-medium {
  font-weight: 500;
}
.fw-semibold {
  font-weight: 600;
}
.fw-bold {
  font-weight: 700;
}
.fw-bolder {
  font-weight: 800;
}
.fw-ultrabold {
  font-weight: 900;
}

h1,
h2,
h3 {
  font-family: var(--font-primary);
}

h4,
h5,
h6 {
  font-family: var(--font-primary);
}

.form-section h2 {
  font-family: var(--font-primary);
}

.header-heading {
  margin-bottom: 15px;
}

a,
a:link,
a:visited,
a:active {
  text-decoration: none;
}

h1,
.h1 {
  color: var(--color-first);
  font-size: var(--h1);
  line-height: 1.6;
}

h2,
.h2 {
  color: var(--color-first);
  font-size: var(--h2);
  line-height: 1.5;
}

h3,
.h3 {
  color: var(--color-first);
  font-size: var(--h3);
  line-height: 1.4;
}

h4,
.h4 {
  font-size: var(--h4);
  line-height: 1.3;
}

h5,
.h5 {
  line-height: 1.2;
}

h6,
.h6 {
  line-height: 1.2;
}

p,
ul li,
ol li {
  line-height: 1.5;
  margin-bottom: 15px;
}
p:last-child,
p:only-child,
ul li:last-child,
ul li:only-child {
  margin-bottom: 0;
}

ul,
ol {
  position: relative;
}

ul li,
ol li {
  list-style: none;
}

ul li a:hover,
ol li a:hover {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

ol li {
  list-style-position: outside;
}

.marker-list li {
  list-style: square;
  list-style-position: inside;
}

iframe {
  border: none;
}

q {
  font-size: 1.125rem;
  line-height: 1.35rem;
  font-style: italic;
  color: #000;
}

/* Misc */
.mb-100 {
  margin-bottom: 100px !important;
}
.mr-1 {
  margin-right: 8px;
}
.mr-2 {
  margin-right: 12px;
}
.ml-1 {
  margin-left: 6px;
}
.ml-2 {
  margin-left: 12px;
}
.mr-5 {
  margin-right: 50px;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.bg-red {
  background-color: rgba(255, 0, 0, 0.25);
}

@media (max-width: 767.99px) {
  .container {
    width: 100%;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024.99px) {
  .container {
    width: 100%;
    max-width: 94%;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1299.99px) {
  .container {
    width: 100%;
    max-width: 92%;
  }
}
@media (min-width: 1300px) {
  .container {
    width: 100%;
    max-width: 1250px;
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.u-flex {
  display: flex;
}
.min-vh-100 {
  min-height: 100vh;
}
.u-upper {
  text-transform: uppercase;
}
.w-full,
.img-fluid {
  width: 100%;
  max-width: 100%;
}
.w-60 {
  width: 100%;
  max-width: 60%;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}

html {
  box-sizing: border-box;
  overflow-x: hidden !important;
}

html,
body {
  margin: 0;
  padding: 0;
  overscroll-behavior-y: none !important;
}

body {
  width: 100vw;
  min-height: 100vh;
}

body {
  min-height: 100vh;
  position: relative;
  color: var(color-third);
  letter-spacing: -0.03em;
  font-weight: var(--wght500);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-primary);
  font-family: var(--font-primary);
  text-transform: uppercase;
}

body.dark-theme {
  letter-spacing: -0.03em;
  color: var(--color-third);
  background-color: var(--color-black);
  /* z-index: 1;
  position: relative;
  background-color: var(--color-primary);
  background-image: url("../images/background/new-bg/MainBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center; */
}

.app-wrapper {
  width: 100%;
  min-height: 100vh;
}
@media (min-width: 768px) {
  .app-wrapper {
    min-height: 1176px;
  }
}

.app-bg2,
.app-bg3,
.app-bg4,
.app-bg5,
.app-bg6 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.app-bg2 {
  background-image: url("../images/background/new-bg/BG2.png");
}
.app-bg3 {
  background-image: url("../images/background/new-bg/BG3.png");
}
.app-bg4 {
  background-image: url("../images/background/new-bg/BG4.png");
}
.app-bg5 {
  background-image: url("../images/background/new-bg/BG5.png");
}
.app-bg6 {
  z-index: -1;
  background-image: url("../images/background/new-bg/mobile_logo_glow@2x.png");
}
@media (max-width: 767px) {
  .app-bg2,
  .app-bg3,
  .app-bg4,
  .app-bg5 {
    display: none;
  }
}

.section-intro {
  width: 100%;
  height: calc(100vh - 123px);
  max-height: calc(100vh - 123px);
  background-image: url("../images/background/IntroBG@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 70px;
}
.paperhand-logo {
  left: 50%;
  top: -100px;
  width: 474px;
  height: 81px;
  content: " ";
  border: 0 none;
  margin-left: -14px;
  position: absolute;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media screen and (min-width: 767px) and (max-width: 991.99px) {
  .paperhand-logo {
    width: 320px;
    height: 55px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199.99px) {
  .paperhand-logo {
    width: 400px;
    height: 68px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1399.99px) {
  .paperhand-logo {
    width: 450px;
    height: 77px;
  }
}

.section-intro-wrap {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.section-intro-wrap .btn-mint-a-croc {
  margin-top: 0 !important;
}
@media (max-width: 767px) {
  .section-intro {
    height: 520px;
    max-height: 520px;
    padding-top: 60px;
    background-image: url("../images/background/IntroBG-Mobile@2x.png");
  }
  .section-intro-wrap {
    max-width: 100%;
  }
}
@media (max-width: 500px) {
  .paperhand-logo {
    top: -80px;
    width: 250px;
    height: 43px;
    margin-left: 2px;
  }
}

@media screen and (min-width: 501px) and (max-width: 767.99px) {
  .paperhand-logo {
    top: -80px;
    width: 275px;
    height: 47px;
    margin-left: 2px;
  }
}

.presale {
  height: 45px;
  padding: 10px 0;
  background-color: var(--color-header);
  background-image: url("../images/presale-bg.png");
  background-repeat: repeat-x;
  background-size: auto 45px;
  vertical-align: center;
  overflow: hidden;
  text-align: center;
  color: var(--color-neon);
  text-transform: uppercase;
  font-size: var(--font-body-1);
  line-height: 25px;
}
.presale .ticker__element {
  white-space: nowrap !important;
}
.presale-wrap {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  -webkit-animation: marquee 300s linear infinite;
  -moz-animation: marquee 300s linear infinite;
  animation: marquee 300s linear infinite;
}
.presale-text {
  display: inline;
  text-transform: uppercase;
  font-size: var(--font-body-1);
  line-height: 25px;
  text-transform: uppercase;
}
@keyframes marquee {
  0% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media (max-width: 767.99px) {
  .presale {
    font-size: var(--font-body-5);
    line-height: 25px;
  }
}

.link-grey {
  color: var(--color-gray-1);
  text-decoration: underline !important;
}
.link-grey:hover {
  color: var(--color-white);
}

.dark-theme .link-primary {
  color: var(--color-white);
  border-bottom: 1px solid var(--color-secondary);
}
.dark-theme .link-primary:hover {
  color: var(--color-secondary);
  transition: var(--transition);
  border-bottom: 1px solid transparent;
}

.dark-theme .link-primary strong,
.dark-theme .link-primary .strong,
.dark-theme .link-primary b,
.dark-theme .link-primary .bold {
  color: var(--color-white);
}

.fs-16 {
  font-size: var(--font-body-2);
  line-height: 20px;
  margin-bottom: 24px;
}
.fs-22 {
  color: var(--color-fourth);
  font-size: var(--font-body-5);
  line-height: 1.1;
  letter-spacing: -0.03em;
}
.fs-24 {
  color: var(--color-third);
  font-size: var(--h6);
  line-height: 1.1;
  letter-spacing: -0.03em;
}
.fs-24b {
  line-height: 1.25;
}
.fs-24b small {
  font-size: 14px;
  line-height: 1.35;
  letter-spacing: -0.03em;
}
.fs-36 {
  font-size: var(--h4);
  color: var(--color-twitter);
}
.twitter-color {
  color: var(--color-twitter);
}
.twitter-color:hover,
.twitter-color:focus,
.twitter-color:active {
  color: var(--color-white);
}

.btn-transparent {
  background-color: transparent !important;
}
.buy-dropdown-holder button {
  position: relative;
}
.buy-dropdown-holder .dropdown-toggle::after {
  top: 2px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0 none !important;
  background-color: transparent !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236ABE30' viewBox='0 0 15 9'%3E%3Cpath d='M1.758 2.742h-.88V.984h1.759v.88h.879v.878h.879v.88h.878V4.5h.88v.879h.878v.879h.88v-.88h.878V4.5h.879v-.879h.879v-.879h.879v-.879h.879V.984h1.758v1.758h-.88v.88h-.878V4.5h-.88v.879h-.878v.879h-.879v.879h-.879v.879H6.152v-.88h-.879v-.878h-.878v-.88h-.88V4.5h-.878v-.879h-.88v-.879Z'/%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.footer-wrap .buy-dropdown-holder .dropdown-toggle::after {
  top: 0;
}

.footer-wrap .buy-dropdown-holder .dropdown-toggle:hover::after,
.footer-wrap .buy-dropdown-holder .dropdown-toggle:focus::after,
.footer-wrap .buy-dropdown-holder .dropdown-toggle:active::after,
.section-intro .buy-dropdown-holder .dropdown-toggle:hover::after,
.section-about .buy-dropdown-holder .dropdown-toggle:hover::after,
.header-horizontal .buy-dropdown-holder button.dropdown-toggle:hover::after,
.section-intro .buy-dropdown-holder .dropdown-toggle:focus::after,
.section-about .buy-dropdown-holder .dropdown-toggle:focus::after,
.header-horizontal .buy-dropdown-holder button.dropdown-toggle:focus::after,
.section-intro .buy-dropdown-holder .dropdown-toggle:active::after,
.section-about .buy-dropdown-holder .dropdown-toggle:active::after,
.header-horizontal .buy-dropdown-holder button.dropdown-toggle:active::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23131125' viewBox='0 0 15 9'%3E%3Cpath d='M1.758 2.742h-.88V.984h1.759v.88h.879v.878h.879v.88h.878V4.5h.88v.879h.878v.879h.88v-.88h.878V4.5h.879v-.879h.879v-.879h.879v-.879h.879V.984h1.758v1.758h-.88v.88h-.878V4.5h-.88v.879h-.878v.879h-.879v.879h-.879v.879H6.152v-.88h-.879v-.878h-.878v-.88h-.88V4.5h-.878v-.879h-.88v-.879Z'/%3E%3C/svg%3E");
}

.section-intro .buy-dropdown-holder .dropdown-toggle::after,
.section-about .buy-dropdown-holder .dropdown-toggle::after {
  top: 1px;
  width: 16px;
  height: 16px;
}

.footer-wrap .buy-dropdown-holder .dropdown-toggle::after {
  transform: rotate(180deg);
}
.header-horizontal .buy-dropdown-holder .dropdown-menu,
.footer-wrap .buy-dropdown-holder .dropdown-menu {
  right: 0 !important;
  left: auto !important;
}

.buy-dropdown {
  padding: 0;
  min-width: 200px;
  max-width: 200px;
  outline: none !important;
  border: 0 none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  /* border: 2px solid #335633; */
  /* background-color: var(--dropdown-bg) !important; */
  background-image: url("../images/dropdownNew@2x.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.buy-dropdown[data-popper-placement="bottom-start"] {
  margin-top: 8px;
}
.buy-dropdown[data-popper-placement="top-start"] {
  margin-top: 0;
  margin-bottom: 8px;
}

.buy-dropdown-wrap,
.buy-dropdown-wrap > li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.buy-dropdown-wrap {
  padding: 0 8px;
}
/* .buy-dropdown-wrap > li {
  border-bottom: 1px solid #335633;
}
.buy-dropdown-wrap > li:last-of-type {
  border-bottom: 0 none;
} */
.buy-dropdown a {
  /* height: 48px; */
  height: 42px;
  font-size: 22px;
  line-height: 26px;
  position: relative;
  color: var(--color-neon);
  padding: 5px 30px 0px 10px;
}
.buy-dropdown li:first-of-type a {
  padding-top: 8px !important;
}

.buy-dropdown a:hover,
.buy-dropdown a:focus,
.buy-dropdown a:active {
  color: var(--color-warning);
}
.buy-dropdown a::after {
  top: 2px;
  right: 7px;
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  align-items: center;
  justify-content: center;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' fill='%236abe30'%3E%3Cpath d='M2 .3v3.1h4v.9h-.9v.9h-.9v1h-1v.9h-.9V8h-.9v.9H0v2.8h3.4v-.9h.9v-1h.9v-.9h1V8h.9v-.9H8v-.9h.9v4.1H12V.3z'/%3E%3C/svg%3E");
}
.buy-dropdown a:hover::after,
.buy-dropdown a:focus::after,
.buy-dropdown a:active::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' fill='%23f6da4e'%3E%3Cpath d='M2 .3v3.1h4v.9h-.9v.9h-.9v1h-1v.9h-.9V8h-.9v.9H0v2.8h3.4v-.9h.9v-1h.9v-.9h1V8h.9v-.9H8v-.9h.9v4.1H12V.3z'/%3E%3C/svg%3E");
}
.buy-dropdown li:first-of-type a::after {
  top: 4px;
}

/* Header Styling */
.header-horizontal {
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  z-index: 12;
  width: 100%;
  height: 78px;
  position: sticky;
  display: flex;
  align-items: center;
  transition: var(--transition);
  background-color: var(--color-header);
  background-image: url("../images/header-bg.png");
  background-size: auto 78px;
  background-repeat: repeat-x;
  background-position: top center;
}
.header-horizontal::before {
  top: 9px;
  left: 50%;
  width: 201px;
  height: 64px;
  content: " ";
  position: absolute;
  pointer-events: none;
  background-image: url("../images/header-bg2.png");
  background-size: auto 64px;
  background-repeat: no-repeat;
  background-position: top center;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.header-logo {
  width: 44px;
  height: 44px;
  display: block;
  margin-top: 5px;
}

.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}

.footer-social,
.header-menu {
  left: 50%;
  margin: 0;
  margin-top: 5px;
  position: absolute;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.header-menu {
  min-width: 870px;
  justify-content: center;
}
@media (max-width: 1250px) {
  .header-menu {
    display: none;
  }
}
.header-menu-item {
  margin: 0 15px;
}
@media screen and (min-width: 768px) and (max-width: 991.99px) {
  .header-menu-item {
    margin: 0 10px;
  }
}
.header-menu-link {
  cursor: pointer;
  text-align: center;
  color: var(--color-neon);
  font-size: var(--font-body-2);
  transition: var(--transition);
}
.header-menu-link:hover,
.header-menu-link:focus,
.header-menu-link:active,
.header-menu-link.is--active {
  color: var(--color-warning);
}

/* Sections */
.section-about,
.section-roadmap,
.section-team,
.section-faqs {
  padding: 90px 0;
}
.section-about .odd,
.section-about .even {
  margin-bottom: 90px;
}
.section-about .odd .section-adjust {
  float: left;
}
.section-about .odd .graphic-adjust {
  float: right;
}
.section-about .even .section-adjust {
  float: right;
}
.section-about .even .graphic-adjust {
  float: left;
}
.section-buttons button {
  margin-right: 24px;
}

@media (max-width: 767.99px) {
  .section-buttons {
    justify-content: center;
  }
}

.section-adjust,
.graphic-adjust {
  width: 500px;
  max-width: 500px;
}
.title {
  font-size: var(--h2);
  line-height: 1;
  margin-bottom: 25px;
  letter-spacing: -0.03rem;
  text-transform: uppercase;
}
.section-team .title span {
  width: 100%;
  display: inline-block;
}
.section-roadmap .title {
  margin-bottom: 90px;
}
.acrocalypse-lists {
  margin: 0;
  padding: 0;
  margin-top: 50px;
  display: flex;
  list-style: none;
  text-align: center;
  align-items: flex-start;
  justify-content: center;
}
.acrocalypse-lists-item {
  width: 100%;
  margin: 0 8px;
  max-width: 250px;
}
.acrocalypse-subtitle {
  margin: 0;
  padding: 0;
  margin-top: 2px;
  text-transform: uppercase;
  font-size: var(--font-body-1);
}

.section-join {
  width: 100%;
  height: 720px;
  padding: 116px 0;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("../images/background/JoinTheAcrocalypse.png");
  background-size: 100% 740px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center bottom;
}

.read-whitepaper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  position: absolute;
}

.section-join-title {
  height: 122px;
  max-width: 436px;
  font-size: 0px;
  text-indent: -999rem;
  background-image: url("../images/Title@2x.png");
  background-size: auto 122px;
  background-repeat: no-repeat;
  background-position: top left;
}

.get-your-croc {
  width: 480px;
  max-width: 100%;
  height: 520px;
  padding: 40px 50px 50px;
  background-image: url("../images/mint_frame.gif");
  background-size: auto 520px;
  background-repeat: no-repeat;
  background-position: top left;
}
.get-your-croc .mb-2 {
  margin-bottom: 20px !important;
}
.get-your-croc-header {
  width: 100%;
  text-align: center;
}
.get-your-croc-header h4 {
  font-size: var(--h2);
  letter-spacing: -0.03em;
  color: var(--color-fourth);
}
.get-your-croc-header p {
  letter-spacing: 0;
  color: var(--color-third);
  text-transform: uppercase;
  font-size: 24px;
  line-height: 1;
}
.get-your-croc .w-80 {
  width: 80px;
  max-width: 80px;
}
.get-your-croc .w-120 {
  width: 120px;
  margin: 0 24px;
  max-width: 120px;
}
.get-your-croc .w-135 {
  width: 135px;
  max-width: 135px;
}
.get-your-croc .w-200 {
  max-width: 215px;
}
.get-your-croc .w-300 {
  width: 100%;
  max-width: 300px;
}
.get-your-croc .form-control {
  padding: 0;
  font-size: 24px;
  max-width: 120px;
  box-shadow: none;
  text-align: center;
  border-radius: 0px;
  color: var(--color-white);
  border: 1px solid #3d2970;
  background-color: transparent;
}
.get-your-croc .croc-minus,
.get-your-croc .croc-plus {
  width: 38px;
  height: 38px;
  display: flex;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  font-family: "VCR_OSD_MONO_1_001";
  background: #bc4df1;
  background: -moz-linear-gradient(
    top,
    rgba(188, 79, 241, 1) 0%,
    rgba(174, 77, 245, 1) 58%,
    rgba(152, 73, 240, 1) 59%,
    rgba(145, 77, 240, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(188, 79, 241, 1) 0%,
    rgba(174, 77, 245, 1) 58%,
    rgba(152, 73, 240, 1) 59%,
    rgba(145, 77, 240, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(188, 79, 241, 1) 0%,
    rgba(174, 77, 245, 1) 58%,
    rgba(152, 73, 240, 1) 59%,
    rgba(145, 77, 240, 1) 100%
  );
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  color: #000;
}
.get-your-croc .croc-minus:hover,
.get-your-croc .croc-minus:focus,
.get-your-croc .croc-minus:active,
.get-your-croc .croc-plus:hover,
.get-your-croc .croc-plus:focus,
.get-your-croc .croc-plus:active {
  background: #914df0;
  background: -moz-linear-gradient(
    top,
    rgba(145, 77, 240, 1) 0%,
    rgba(152, 73, 240, 1) 58%,
    rgba(174, 77, 245, 1) 59%,
    rgba(188, 79, 241, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(145, 77, 240, 1) 0%,
    rgba(152, 73, 240, 1) 58%,
    rgba(174, 77, 245, 1) 59%,
    rgba(188, 79, 241, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(145, 77, 240, 1) 0%,
    rgba(152, 73, 240, 1) 58%,
    rgba(174, 77, 245, 1) 59%,
    rgba(188, 79, 241, 1) 100%
  );
}

@media (max-width: 767.99px) {
  .get-your-croc .croc-minus,
  .get-your-croc .croc-plus {
    width: 24px;
    height: 24px;
  }
}

.get-your-croc .total-box {
  width: 100%;
  height: 46px;
  display: flex;
  padding: 0 12px;
  align-items: center;
  justify-content: flex-end;
  background-image: url("../images/croc_ava_mint_field.png");
}
.get-your-croc button.btn-connect-wallet {
  width: 240px;
  height: 57px;
  margin: 0 auto;
  display: flex;
  font-size: 24px;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: var(--color-warning);
  background-image: url("../images/croc_ava_mint_btn.gif");
  background-size: 100% 57px;
  background-repeat: no-repeat;
  background-position: top center;
}
.get-your-croc button.btn-connect-wallet:hover,
.get-your-croc button.btn-connect-wallet:focus,
.get-your-croc button.btn-connect-wallet:active {
  background-image: url("../images/croc_ava_mint_btn_hover.gif");
}

button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.roadmap-timeline2,
.roadmap-timeline {
  min-height: 1168px;
  padding-left: 50px;
  padding-right: 50px;
  background-size: 60% auto;
  background-position: top center;
}
.roadmap-timeline {
  background-image: url("../images/roadmap_grid@2x.png");
  background-repeat: no-repeat;
}
.roadmap-timeline2 {
  background-image: url("../images/roadmap2.0@2x.png");
  background-repeat: repeat-y;
}
.roadmap-timeline-lists2,
.roadmap-timeline-lists {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  margin-bottom: 60px;
}
.roadmap-timeline-lists::before {
  left: 50%;
  top: -100px;
  width: 774px;
  content: " ";
  height: 1168px;
  max-width: 774px;
  max-height: 1168px;
  position: absolute;
  pointer-events: none;
  background-image: url("../images/roadmap_lines@2x.png");
  background-position: top center;
  background-size: 100% auto;
  -webkit-transform: translateX(-53%);
  -moz-transform: translateX(-53%);
  -ms-transform: translateX(-53%);
  transform: translateX(-53%);
}
.roadmap-timeline-lists-items {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}

@media (min-width: 1199.99px) {
  .roadmap-timeline-lists-items:nth-child(3) {
    margin-top: -86px;
  }
  .roadmap-timeline-lists-items:nth-child(4) {
    margin-top: 55px;
  }
  .roadmap-timeline-lists-items:nth-child(5) {
    margin-top: -100px;
  }
}

.roadmap-timeline-lists-items .roadmap-timeline-wrap {
  width: 100%;
}

.roadmap-timeline-lists-items .roadmap-timeline-inner {
  display: table;
  max-width: 370px;
}
.roadmap-timeline-lists-items:nth-child(even) .roadmap-timeline-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: -15px;
}
.roadmap-timeline-inner .title {
  margin-bottom: 24px;
}
.roadmap-timeline .date {
  z-index: 2;
  width: 180px;
  height: 57px;
  padding: 10px;
  display: flex;
  margin-left: 0;
  text-align: center;
  position: relative;
  align-items: center;
  margin-bottom: 24px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  color: var(--color-third);
  font-size: var(--font-body-1);
  background-image: url("../images/date-bg@2x.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 57px;
  text-shadow: 0 1px 9px rgba(179, 180, 188, 0.72);
}
.roadmap-timeline .date.first {
  color: var(--color-neon);
  text-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);
}
.roadmap-timeline .date::before {
  left: 50%;
  top: -14px;
  width: 130px;
  height: 10px;
  content: " ";
  position: absolute;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -mos-transform: translateX(-50%);
  transform: translateX(-50%);
  background-image: url("../images/date-top@2x.png");
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 10px;
}
.roadmap-timeline .date span {
  display: none;
}
.roadmap-timeline p {
  color: var(--color-third);
  font-size: var(--font-body-1);
  line-height: 1.2;
}
.roadmap-version {
  width: 320px;
  height: 110px;
  background-image: url("../images/background/roadmap_milestone_version@2x.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 110px;
}
.roadmap-version span {
  text-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);
}
@media (max-width: 767.99px) {
  .roadmap-version {
    width: 244px;
    height: 84px;
    background-size: 100% 84px;
  }
}

/* Team */
.section-team {
  padding-bottom: 100px;
}
.team-username {
  left: 50%;
  width: 249px;
  height: 74px;
  bottom: -70px;
  font-size: 26px;
  padding: 15px 0;
  line-height: 40px;
  text-align: center;
  position: absolute;
  color: var(--color-neon);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -mos-transform: translateX(-50%);
  transform: translateX(-50%);
  background-image: url("../images/Team-Bg.png");
  background-size: 100% 74px;
  text-shadow: 0 1px 9px rgba(115, 255, 66, 0.72);
}
@media (max-width: 767px) {
  .team-username {
    width: 202px;
    height: 60px;
    bottom: -57px;
    font-size: 22px;
    padding: 9px 0;
    line-height: 40px;
    background-size: 100% 60px;
  }
}
.team-controls {
  left: 50%;
  width: 341px;
  height: 40px;
  display: flex;
  bottom: -52px;
  margin: 0 auto;
  position: absolute;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -mos-transform: translateX(-50%);
  transform: translateX(-50%);
}
.team-controls .btn-left,
.team-controls .btn-right {
  width: 36px;
  height: 40px;
  font-size: 0;
  display: flex;
  border: 0 none;
  cursor: pointer;
  position: absolute;
  align-items: center;
  text-indent: -999rem;
  justify-content: center;
  background-size: 100% 40px;
  background-repeat: no-repeat;
  background-position: center center;
  transition: var(--transition);
}
.team-controls .btn-left {
  left: 0;
  background-image: url("../images/icons/arrows/arrow_left@2x.png");
}
.team-controls .btn-left:hover,
.team-controls .btn-left:focus,
.team-controls .btn-left:active {
  background-image: url("../images/icons/arrows/arrow_left-h@2x.png");
}

.team-controls .btn-right {
  right: 0;
  background-image: url("../images/icons/arrows/arrow_right@2x.png");
}
.team-controls .btn-right:hover,
.team-controls .btn-right:focus,
.team-controls .btn-right:active {
  background-image: url("../images/icons/arrows/arrow_right-h@2x.png");
}

/* FAQs */
.faq-row-wrapper {
  margin-bottom: 100px !important;
}
.faq-row-wrapper,
.faq-body .faq-row {
  background-color: transparent !important;
}
.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c {
  border-bottom: 1px solid var(--color-neon) !important;
}
.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY {
  padding: 30px 0 !important;
}
.faq-row-wrapper .row-title-text {
  font-size: var(--h4);
  font-weight: var(--wght400);
  padding-right: 40px !important;
  color: var(--color-first) !important;
}
.faq-row-wrapper .row-content-text {
  color: var(--color-third);
  padding: 5px 0 30px !important;
}
.faq-row-wrapper .styles_icon-wrapper__2cftw {
  width: 36px;
  height: 36px;
  font-size: 30px !important;
  max-width: 36px !important;
  max-height: 36px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0 !important;
  top: 50% !important;
  border-radius: 0 !important;
  color: var(--color-black) !important;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
  background: var(--color-neon);
  background: -moz-linear-gradient(
    top,
    rgba(163, 233, 73, 1) 0%,
    rgba(163, 233, 73, 1) 58%,
    rgba(106, 190, 48, 1) 59%,
    rgba(106, 190, 48, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(163, 233, 73, 1) 0%,
    rgba(163, 233, 73, 1) 58%,
    rgba(106, 190, 48, 1) 59%,
    rgba(106, 190, 48, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(163, 233, 73, 1) 0%,
    rgba(163, 233, 73, 1) 58%,
    rgba(106, 190, 48, 1) 59%,
    rgba(106, 190, 48, 1) 100%
  );
}
.faq-row-wrapper .styles_icon-wrapper__2cftw::before {
  content: "+";
  margin-top: -5px;
  position: relative;
}
.faq-row-wrapper .row-title.expanding .styles_icon-wrapper__2cftw::before,
.faq-row-wrapper .row-title.expanded .styles_icon-wrapper__2cftw::before {
  content: "-";
}

.row-title.expanded .icon-wrapper {
  border: 2px solid var(--color-neon) !important;
}
.faq-row-wrapper .row-title.expanded .styles_icon-wrapper__2cftw {
  background: transparent !important;
}
.faq-row-wrapper .row-title.expanded .styles_icon-wrapper__2cftw::before {
  color: var(--color-neon) !important;
}

.faq-body li,
.faq-body p {
  color: var(--color-third);
  font-size: var(--font-body-1);
  line-height: 1;
  letter-spacing: -0.005em;
}
.faq-body ul,
.faq-body ol {
  padding-left: 30px;
  margin-bottom: 30px;
  color: var(--color-third);
}
.faq-body ul li {
  color: var(--color-third);
  list-style: disc !important;
}
.faq-body ul li {
  list-style-position: outside;
}
.faq-body ul li li {
  list-style: circle !important;
}
.faq-body ol li {
  list-style-position: outside;
}
.faq-body ol li {
  list-style: decimal !important;
}
.faq-body ol li li {
  list-style: decimal !important;
}
.faq-body ul ul,
.faq-body ul ol,
.faq-body ol ol {
  margin-top: 30px;
  color: var(--color-third);
}
.faq-body strong,
.faq-body .strong,
.faq-body b,
.faq-body .bold {
  color: var(--color-third);
}
.faq-body img {
  width: 100%;
  border-radius: 4px;
  margin-bottom: 30px;
}
.faq-body p img {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section-about,
  .section-roadmap,
  .section-team,
  .section-faqs {
    padding: 70px 0;
  }
  .section-about .odd,
  .section-about .even {
    margin-bottom: 60px;
  }
  .section-adjust {
    width: 100%;
    max-width: 100%;
  }
  .graphic-adjust {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  .graphic-adjust {
    margin-top: 60px;
  }
  .title {
    font-size: var(--h3);
    line-height: 1;
    margin-bottom: 24px;
  }

  .section-join-title {
    height: 84px;
    margin: 0 auto;
    max-width: 300px;
    background-image: url("../images/Title-Mobile@2x.png");
    background-size: auto 84px;
  }
}

.app-position {
  width: 100%;
  margin: 70px auto 0;
  max-width: 768px;
  padding-bottom: 50px;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-position-wrap {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding-top: 210px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.page-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1098px;
  max-width: 1098px;
  cursor: pointer;
}
.page-logo .img-fluid {
  width: 100%;
}

@media (max-width: 767px) {
  .page-logo {
    width: 100%;
    max-width: 500px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199.99px) {
  .page-logo {
    width: auto;
    max-width: 95%;
  }
}

/* Margin Bottom */
.mb-2 {
  margin-bottom: 24px !important;
}
.mb-90 {
  margin-bottom: 90px;
}
@media (max-width: 767px) {
  .mb-90 {
    margin-bottom: 60px;
  }
}

.order-md-2 .pull-right,
.order-md-1 .pull-right {
  float: left !important;
}

.app-position-inner {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 0 100px;
  font-size: var(--font-body-1);
  line-height: 1;
}
.app-position-inner .col-lg-8 {
  padding-right: 30px;
}
.app-position-inner p {
  font-size: var(--font-body-1);
  line-height: 1;
  margin-bottom: 18px;
}
.app-position-inner p:last-child,
.app-position-inner p:only-child {
  margin-bottom: 0;
}

.app-position-inner .spinner {
  margin: 0 auto 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-position-inner .btn-secondary {
  border: 0 none;
  font-size: var(--font-body-1);
  line-height: 1;
  padding: 9px 20px;
  max-height: 40px;
  border-radius: 0.5rem;
  outline: none !important;
  color: var(--color-black);
  background-color: var(--color-neon);
}
.app-position-inner .btn-secondary:hover,
.app-position-inner
  .btn-secondary:focus
  .app-position-inner
  .btn-secondary:active {
  opacity: 0.85;
  transition: var(--transition);
}
.btn {
  outline: none !important;
  box-shadow: none !important;
}
.app-position-inner .spinner .btn-secondary.btn-sm {
  padding: 0;
  width: 26px;
  height: 26px;
  max-width: 26px;
  flex: 0 0 26px;
  display: flex;
  text-indent: 1px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--color-white);
}
.spinner-inner {
  width: 90px;
  max-width: 90px;
}
.spinner-inner .form-control {
  padding: 0;
  border: 0 none;
  max-height: 26px;
  font-size: var(--font-body-1);
  text-align: center;
  letter-spacing: 1px;
  color: var(--color-neon);
  background-color: transparent;
  box-shadow: none !important;
}

.right-panel {
  display: flex;
  min-width: 300px;
  max-width: 300px;
  max-height: 340px;
  padding-left: 20px;
  align-items: center;
  justify-content: center;
}

.alert {
  padding: 10px 15px;
  margin-bottom: 18px;
  border-radius: 0.5rem;
  font-size: 18px;
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}
.social li {
  padding: 0;
  margin: 0 15px;
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
}

/* Thumbnails  */
.size-24 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.size-24 {
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  flex: 0 0 24px;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.social-links {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 50px;
  list-style: none;
  margin-top: 24px;
}
.social-links-items {
  padding: 0;
  width: 50px;
  height: 50px;
  display: flex;
  margin: 0 6px;
  align-items: center;
  justify-content: center;
}

/*  */
.twitter-white,
.discord-white,
.twitter-gray,
.discord-gray,
.twitter-icon {
  background-size: 50px auto;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.4s ease;
}
.twitter-white,
.discord-white {
  width: 50px;
  height: 50px;
}
.twitter-icon {
  width: 40px;
  height: 40px;
}

.twitter-white {
  background-image: url("../images/icons/twitter_icon_glow@2x.png");
}
.discord-white {
  background-image: url("../images/icons/discord_icon_glow@2x.png");
}
.opensea-white {
  background-image: url("../images/icons/opensea_icon_glow@2x.png");
}
.etherscan-white {
  background-image: url("../images/icons/etherscan_icon_glow@2x.png");
}
.twitter-icon {
  background-image: url("../images/icons/twitter_iconblue_glow@2x.png");
  background-size: 40px auto;
}

.max-w200 {
  max-width: 200px;
}

.mint-frame {
  z-index: 2;
  width: 442px;
  max-width: 442px;
  min-height: 340px;
  max-height: 340px;
  position: relative;
  background-image: url("../images/background/new-bg/frame@2x.gif");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 442px auto;
}
/* .mint-frame-top {
  left: 0;
  top: -30px;
  z-index: -1;
  width: 100%;
  height: 170px;
  max-height: 170px;
  position: absolute;
  background-image: url('../images/background/new-bg/frame-top.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 440px auto;
} */

.mint-frame-body {
  z-index: 2;
  max-height: 340px;
  position: relative;
  font-size: var(--h5);
  padding: 59px 90px 70px;
  color: var(--color-neon);
}
.mint-frame-body p {
  font-size: var(--h5);
  margin-bottom: 18px;
}
.mint-frame-body .date {
  margin-bottom: 18px;
}
.mint-frame-body .mb-2 {
  margin-bottom: 15px !important;
}
.mint-frame-body .btn-tertiary,
.btn-mint-a-croc {
  padding: 0;
  width: 143px;
  height: 41px;
  max-width: 143px;
  max-height: 41px;
  border: 0 none;
  display: flex;
  position: relative;
  padding-bottom: 2px;
  align-items: center;
  font-size: var(--h6);
  justify-content: center;
  color: var(--color-neon);
  background-image: url("../images/button_mint.gif");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 100% 41px;
  border-radius: 0 !important;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.mint-frame-body .btn-tertiary:hover,
.btn-mint-a-croc:hover,
.btn-mint-a-croc:focus,
.btn-mint-a-croc:active,
.mint-frame-body .btn-tertiary.active,
.btn-mint-a-croc.active {
  color: var(--color-primary) !important;
  background-image: url("../images/button_mint_hover.gif");
}

.btn-mint-a-croc {
  width: 144px;
  height: 41px;
  margin-top: 3px;
  max-width: 144px;
  max-height: 41px;
  color: var(--color-neon);
  background-size: 100% 41px;
  font-size: var(--font-body-3);
}
.btn-mint-a-croc:hover {
  color: var(--color-neon);
}

.btn-mint-a-croc.lg {
  width: 200px;
  height: 57px;
  margin-top: 10px;
  max-width: 200px;
  max-height: 57px;
  background-size: 100% 57px;
  font-size: var(--font-body-1);
}

.show > .btn-secondary.dropdown-toggle {
  color: var(--color-neon);
}

/* Toastify */
.Toastify__toast-body {
  font-size: var(--font-body-3);
  line-height: 1.35;
  color: var(--color-primary);
  font-family: var(--font-primary);
  align-items: flex-start !important;
}
.Toastify__toast-icon {
  margin-top: 2px;
}
.Toastify__close-button {
  margin-top: 6px;
}

/* Footer */
.footer-wrap {
  width: 100%;
  height: 78px;
  position: relative;
  padding: 1.25rem 0;
  color: var(--color-gray-1);
  font-size: var(--font-body-3);
  display: flex;
  align-items: center;
  transition: var(--transition);
  background-color: var(--color-header);
  background-image: url("../images/footer-bg.png");
  background-size: auto 78px;
  background-repeat: repeat-x;
  background-position: top center;
}
.footer-wrap::before {
  top: 0;
  left: 50%;
  width: 217px;
  height: 78px;
  content: " ";
  position: absolute;
  pointer-events: none;
  background-image: url("../images/footer-bg2.png");
  background-size: auto 78px;
  background-repeat: no-repeat;
  background-position: top center;
  transform: translateX(-50%);
}
.footer-logo {
  width: 44px;
  height: 44px;
  display: block;
}
.footer-wrap .footer-logo,
.footer-wrap .btn-mint-a-croc,
.footer-wrap .header-menu {
  margin-top: -2px;
}

.footer-social {
  display: flex;
  margin-top: -7px;
  align-items: center;
}
.footer-social .footer-social-item {
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  margin: 0 16px;
  position: relative;
  align-items: center;
}
.footer-social .footer-social-item .footer-social-link {
  width: 32px;
  height: 32px;
  display: flex;
  /* overflow: hidden; */
}
.footer-social .twitter-white,
.footer-social .discord-white,
.footer-social .opensea-white,
.footer-social .etherscan-white {
  width: 32px;
  height: 32px;
  background-size: 32px auto;
}
.twitter-white:hover,
.twitter-white:focus,
.twitter-white:active {
  background-image: url("../images/icons/twitter_icon_glow-hover@2x.png");
}
.twitter-color:hover .twitter-icon,
.twitter-color:focus .twitter-icon,
.twitter-color:active .twitter-icon {
  background-image: url("../images/icons/twitter_icon_glow-hover@2x.png");
}

.discord-white:hover,
.discord-white:focus,
.discord-white:active {
  background-image: url("../images/icons/discord_icon_glow-hover@2x.png");
}
.opensea-white:hover,
.opensea-white:focus,
.opensea-white:active {
  background-image: url("../images/icons/opensea_icon_glow-hover@2x.png");
}
.etherscan-white:hover,
.etherscan-white:focus,
.etherscan-white:active {
  background-image: url("../images/icons/etherscan_icon_glow-hover@2x.png");
}

/* Responsive Fixes */
@media (max-width: 767.99px) {
  .footer-wrap .footer-logo,
  .footer-wrap .btn-mint-a-croc {
    display: none;
  }
  .footer-wrap .footer-social {
    width: 100%;
    justify-content: center;
  }
  .fs-24 {
    font-size: var(--font-body-5);
    line-height: 1.1;
  }
  h2,
  .h2 {
    font-size: var(--h3);
    line-height: 1.15;
  }
  .title {
    font-size: var(--h4);
    line-height: 1;
  }
  .faq-row-wrapper .row-title-text {
    font-size: 26px;
    padding-right: 50px !important;
  }
  .twitter-white,
  .discord-white,
  .size-50 {
    width: 40px;
    height: 40px;
    max-width: 40px;
    max-height: 40px;
    flex: 0 0 40px;
    background-size: 40px auto;
  }
  .roadmap-timeline p,
  .faq-body li,
  .faq-body p {
    font-size: var(--font-body-2);
    line-height: 1.2;
  }
  .faq-row-wrapper .styles_icon-wrapper__2cftw {
    width: 30px;
    height: 30px;
    font-size: 24px !important;
    max-width: 30px !important;
    max-height: 30px !important;
  }
  .mb-100 {
    margin-bottom: 50px !important;
  }

  .app-position {
    height: auto;
    padding-bottom: 0;
  }
  .app-position-wrap {
    height: auto;
    padding-top: 120px;
    max-height: inherit;
    align-items: flex-start;
  }
  .app-position-inner {
    padding: 60px 0;
    max-width: 100%;
  }
  .app-position-inner .col-lg-8 {
    padding-right: 15px;
  }

  .right-panel {
    padding: 60px 0;
    min-width: 190px;
    max-width: 190px;
    margin-left: auto;
    margin-right: auto;
  }

  .price-each {
    display: none;
  }

  .footer-wrap {
    position: relative;
  }

  .gallaxy {
    display: none;
  }

  .mint-frame {
    z-index: 2;
    width: 344px;
    max-width: 344px;
    min-height: 265px;
    max-height: 265px;
    margin-left: auto;
    margin-right: auto;
    background-size: 344px auto;
  }
  .mint-frame-body {
    max-height: 265px;
    padding: 45px 40px 50px;
    color: var(--color-neon);
  }
  .mint-frame-body p {
    font-size: var(--h6);
  }
  .mint-frame-body .btn-tertiary {
    padding: 0;
    width: 166px;
    height: 60px;
    max-width: 166px;
    max-height: 60px;
    background-size: auto 60px;
    font-size: var(--font-body-2);
  }

  .roadmap-timeline {
    padding-left: 0;
    padding-right: 0;
    background-image: none !important;
  }
  .section-roadmap .title {
    margin-bottom: 60px;
  }
  .roadmap-timeline-lists::before {
    display: none;
  }
  .roadmap-timeline-lists-items .roadmap-timeline-wrap,
  .roadmap-timeline-lists-items:nth-child(2n) .roadmap-timeline-wrap {
    margin-top: 45px;
  }
  .roadmap-timeline .date,
  .roadmap-timeline-inner .title {
    margin-bottom: 16px;
  }
  .roadmap-timeline-inner .title {
    font-size: var(--h4);
    line-height: 1.1;
  }
  .roadmap-timeline .date {
    margin-left: -22px;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
  }

  .get-your-croc {
    width: 100%;
    max-width: 306px;
    height: 332px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 15px 25px;
    background-size: auto 332px;
  }
  .get-your-croc .mb-2 {
    margin-bottom: 16px !important;
  }
  .get-your-croc-header h4 {
    font-size: var(--h5);
  }
  .get-your-croc-header p {
    font-size: var(--font-body-3);
    line-height: 1;
  }
  .get-your-croc .w-80 {
    width: 80px;
    max-width: 80px;
  }
  .get-your-croc .w-135 {
    width: 86px;
    max-width: 86px;
  }
  .get-your-croc .w-200 {
    max-width: 170px;
  }
  .get-your-croc .w-300 {
    width: 100%;
    max-width: 190px;
  }
  .get-your-croc .form-control {
    font-size: 16px;
  }
  .get-your-croc .total-box {
    width: 100%;
    height: 30px;
    padding: 0 12px;
    background-size: 100% 30px;
  }
  .get-your-croc button {
    width: 136px;
    height: 37px;
    font-size: 13px;
    background-size: 100% 37px;
  }

  .order-md-2 .pull-right,
  .order-md-1 .pull-right,
  .section-about .odd .graphic-adjust {
    float: none !important;
  }
  .graphic-adjust.pull-right,
  .order-md-2 .pull-right,
  .order-md-1 .pull-right {
    float: none !important;
  }

  .acrocalypse-lists {
    flex-wrap: wrap;
  }
  .acrocalypse-lists-item {
    margin: 0;
    width: 100%;
    padding: 0 5px;
    max-width: 210px;
    flex: 0 0 50%;
    margin-bottom: 40px;
  }
  .acrocalypse-subtitle {
    font-size: var(--font-body-2);
  }
  .btn-mint-a-croc.lg {
    width: 144px;
    height: 41px;
    max-width: 144px;
    max-height: 41px;
    background-size: 100% 41px;
    font-size: var(--font-body-3);
  }

  .section-join {
    background-size: cover;
  }

  .get-your-croc button.btn-connect-wallet {
    width: 190;
    height: 45px;
    font-size: 18px;
    background-size: auto 45px;
  }

  /*  */
  .sm\:mmt-20 {
    margin-top: 1.25rem !important;
  }
  .get-your-croc .mb-2.sm\:mmb-10 {
    margin-bottom: 0.781rem !important; /* 12.5px */
  }
  .sm\:mmb-12 {
    margin-bottom: 0.75rem !important;
  }
  .sm\:mmb-35 {
    margin-bottom: 1.5rem !important;
  }
  .sm\:flex-row {
    flex-direction: row !important;
  }
  .sm\:flex-col {
    flex-direction: column !important;
  }
  .sm\:flex-start {
    align-items: flex-start !important;
  }
  .sm\:justify-center {
    justify-content: center !important;
  }
  .mr-5 {
    margin-right: 20px;
  }
  .sm\:text-center {
    text-align: center !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199.99px) {
  .section-adjust {
    width: 100%;
    max-width: 100%;
  }
  .graphic-adjust {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 1299.99px) {
  .roadmap-timeline {
    background-image: none !important;
  }
  .roadmap-timeline-lists::before {
    display: none;
  }
  .roadmap-timeline-lists-items {
    margin-top: 45px;
  }
  .roadmap-timeline-lists-items:first-of-type {
    margin-top: 0;
  }
}

@media (max-width: 1199.99px) {
  .section-join {
    height: auto !important;
    background-size: cover !important;
  }
  .section-join-title,
  .get-your-croc {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 767px) and (max-width: 1199.99px) {
  .section-join-title {
    height: 112px;
    margin: 0 auto;
    max-width: 400px;
    margin-bottom: 80px;
    background-image: url("../images/Title-Mobile@2x.png");
    background-size: auto 112px;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1520px) {
  .section-join {
    height: 840px;
    background-size: 100% 840px;
  }
}
@media screen and (min-width: 1521px) and (max-width: 1620px) {
  .section-join {
    height: 900px;
    background-size: 100% 900px;
  }
}
@media screen and (min-width: 1621px) and (max-width: 1720px) {
  .section-join {
    height: 956px;
    background-size: 100% 956px;
  }
}
@media screen and (min-width: 1721px) and (max-width: 1820px) {
  .section-join {
    height: 1012px;
    background-size: 100% 1012px;
  }
}
@media screen and (min-width: 1821px) and (max-width: 1920px) {
  .section-join {
    height: 1080px;
    background-size: 100% 1080px;
  }
}
@media (min-width: 1920.99px) {
  .section-join {
    height: 1080px;
    background-size: 100% 1080px;
  }
}

.navbar-toggler {
  display: none;
  background: transparent !important;
  background-color: transparent !important;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28106, 190, 48, 1%29' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
.navbar-light .navbar-toggler-icon:hover {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28106, 190, 48, 0.75%29' stroke-linecap='square' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
@media (max-width: 1250px) {
  .navbar {
    width: 100%;
    position: relative;
    display: block !important;
  }
  .navbar-toggler {
    display: block !important;
    color: #fff;
    position: absolute;
    border: 0 !important;
    box-shadow: none !important;
    background-color: initial !important;
    padding: 0.125rem 0.375rem !important;
  }
  .navbar-light .navbar-toggler {
    top: -14px !important;
    right: -8px !important;
  }

  .header-horizontal .btn-mint-a-croc {
    top: -19px;
    right: 45px;
    position: absolute;
  }
  .navbar .responsive-navigation.show {
    visibility: visible;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media (max-width: 1250px) {
  .navbar {
    width: 100%;
    position: relative;
    display: block !important;
  }
  .navbar .collapse:not(.show) {
    display: block !important;
  }
  .navbar .container-fluid {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .navbar .navbar-nav {
    margin-top: 0;
    border-radius: 8px;
    background-color: var(--color-menu);
  }
  .navbar .nav-link {
    font-size: 18px;
    cursor: pointer;
    text-align: left;
    height: auto !important;
    color: var(--color-white) !important;
    border: 0 none !important;
    font-weight: var(--wght500);
    padding: 1rem 1.5rem !important;
  }
  .navbar .nav-link:hover,
  .navbar .nav-link:focus,
  .navbar .nav-link:active,
  .navbar .nav-link.is-active {
    color: var(--color-warning) !important;
  }
  .navbar .nav-link::before {
    display: none;
  }
  .menu-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    content: " ";
    z-index: 9999;
    position: fixed;
    background: rgba(0, 0, 0, 0.65);
    transition: all ease 0.3s;
  }
  .navigation-active .menu-overlay {
    display: block;
    transition: all ease 0.3s;
  }

  .responsive-navigation {
    top: 0;
    right: 0;
    width: 280px;
    overflow: hidden;
    text-align: left;
    z-index: 99999999;
    padding: 5rem 0rem 1.875rem;
    position: fixed !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 100% !important;
    background-color: var(--color-menu);
    min-height: 100% !important;
    max-height: 100% !important;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    transform: translateX(100%);
    transition: all 0.3s ease;
  }
  .responsive-navigation .close-menu {
    z-index: 999;
    top: 1.25rem; /* 20px */
    left: 1.375rem; /* 20px */
    color: var(--color-neon);
    cursor: pointer;
    position: absolute;
    width: 25px;
    height: 25px;
    font-size: 25px;
    line-height: 1.5em;
    padding: 0;
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0);
    transition: all 0.6s ease;
    transition-duration: 0.6s, 0.5s, 0.5s;
    transition-property: color, opacity, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  [class^="icon-close"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-top: 0px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    transition: all 0.5s ease-in-out;
  }
  [class^="icon-close"]::before,
  [class^="icon-close"]::after {
    top: 50%;
    left: -15%;
    right: -15%;
    content: " ";
    height: 1.5px;
    position: absolute;
    background-color: var(--color-neon);
    transition: all 0.2s ease-in-out;
  }
  [class^="icon-close"]::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  [class^="icon-close"]::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  [class^="icon-close"]:hover::before,
  [class^="icon-close"]:hover::after {
    background-color: var(--color-warning);
  }

  .responsive-navigation.show {
    visibility: visible;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
  .responsive-navigation.show .close-menu {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }

  .header-menu-item {
    margin: 0;
  }
  .header-menu-link {
    display: block;
    text-align: left;
    padding: 10px 24px;
  }
}

.section-roadmap .card {
  border: 0 none;
  background-color: transparent;
}
.section-roadmap .button-title,
.section-roadmap .button-title:hover,
.section-roadmap .button-title:focus,
.section-roadmap .button-title:active {
  border: 0 none;
  background-color: transparent;
}
.section-roadmap .button-title.is-active[id="toggler"] {
  margin-bottom: 75px;
}
.section-roadmap .button-title[id="toggler2"] {
  margin-top: 75px;
  margin-bottom: 75px;
}
.section-roadmap .button-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-roadmap .button-title .icon-20 {
  top: 2px;
  width: 36px;
  height: 36px;
  display: flex;
  position: relative;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
}
.section-roadmap .button-title .icon-20 img {
  width: 36px;
  height: 36px;
}
.section-roadmap .button-title.is-active .icon-20 {
  transform: rotate(90deg);
}
.roadmap-timeline2 .roadmap-timeline-lists-items .roadmap-timeline-wrap {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.roadmap-timeline2 .roadmap-timeline-message {
  width: 258px;
  height: 74px;
  margin: 0 auto;
  font-size: 20px;
  text-align: left;
  position: relative;
  line-height: 22px;
  margin-bottom: 35px;
  padding: 14px 14px 10px 85px;
  color: var(--color-warning2);
  background-image: url("../images/background/content-bg@2x.png");
  background-size: 258px 74px;
  background-repeat: no-repeat;
}

.icon-50px {
  top: 14px;
  left: 15px;
  width: 46px;
  height: 46px;
  display: flex;
  user-select: none;
  position: absolute;
  pointer-events: none;
  background-color: transparent;
}

.roadmap-timeline2 .roadmap-timeline-lists-items {
  margin-top: 0;
  margin-bottom: 95px !important;
}
.roadmap-timeline2 .roadmap-timeline-lists-items .roadmap-timeline-inner {
  width: 100%;
  margin: 0 auto;
  max-width: 690px;
}
.roadmap-timeline2 .roadmap-timeline-colabs {
  width: 390px;
  height: 114px;
  margin: 0 auto;
  font-size: 20px;
  margin-bottom: 0;
  line-height: 23px;
  text-align: center;
  padding: 35px 24px 23px;
  color: var(--color-warning2);
  background-image: url("../images/background/collab_tag@2x.png");
  background-size: 390px 114px;
  background-repeat: no-repeat;
}

@media (max-width: 480px) {
  .section-about .section-buttons {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;
  }
}

.section-intro .buy-dropdown-holder .btn-mint-a-croc,
.section-about .section-buttons .btn-mint-a-croc {
  width: 200px;
  height: 57px;
  margin-top: 10px;
  max-width: 200px;
  max-height: 57px;
  background-size: 100% 57px;
  font-size: var(--font-body-1);
}

@media (max-width: 767px) {
  .roadmap-timeline2 {
    padding-left: 0;
    padding-right: 0;
  }
  .roadmap-timeline2 .roadmap-timeline-lists-items,
  .roadmap-timeline2 .roadmap-timeline-wrap {
    margin-top: 0 !important;
    margin-bottom: 75px !important;
  }
  .roadmap-timeline2 .roadmap-timeline-message {
    width: 188px;
    height: 54px;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 35px;
    padding: 9px 10px 8px 60px;
    background-size: 188px 54px;
    background-repeat: no-repeat;
  }

  .roadmap-timeline2 .roadmap-timeline-colabs {
    width: 300px;
    height: 88px;
    padding: 20px;
    font-size: 16px;
    line-height: 18px;
    background-size: 100% 88px;
    background-repeat: no-repeat;
  }

  .section-roadmap .card-body {
    padding: 0;
  }
  .section-roadmap .button-title[id="toggler2"] {
    margin-top: 0;
  }

  .section-roadmap .button-title .icon-20,
  .section-roadmap .button-title .icon-20 img {
    width: 26px;
    height: 26px;
  }
  .section-roadmap .button-title,
  .section-roadmap .button-title:hover,
  .section-roadmap .button-title:focus,
  .section-roadmap .button-title:active {
    margin-bottom: 55px;
  }

  .team-controls {
    bottom: -48px;
    max-width: 284px;
  }
  .team-controls .btn-left,
  .team-controls .btn-right {
    width: 32px;
    height: 35px;
    background-size: 100% 35px;
  }

  .icon-50px {
    top: 10px;
    left: 11px;
    width: 34px;
    height: 34px;
  }
}

@media (max-width: 1250px) {
  .header-horizontal .buy-dropdown-holder .buy-dropdown.dropdown-menu {
    right: 45px !important;
    transform: translate3d(0, 22px, 0) !important;
  }
}
